<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpReferenceTableIntrinsicService'
import takeUpReferenceTableIntrinsicGradeRuleService from '../api/takeUpReferenceTableIntrinsicGradeRuleService'
import takeUpReferenceTableIntrinsicRuleService from '../api/takeUpReferenceTableIntrinsicRuleService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getTakeUpReferenceTableIntrinsicGradeRuleMeta() {
      const takeUpReferenceTableIntrinsicGradeRuleMeta =
        await takeUpReferenceTableIntrinsicGradeRuleService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpReferenceTableIntrinsicGradeRuleMeta,
        'takeUpReferenceTableIntrinsicGradeRule',
        'referenceTableId',
        'referenceTable',
        takeUpReferenceTableIntrinsicGradeRuleService
      )
      return takeUpReferenceTableIntrinsicGradeRuleMeta
    },
    async getTakeUpReferenceTableIntrinsicRuleMeta() {
      const takeUpReferenceTableIntrinsicRuleMeta =
        await takeUpReferenceTableIntrinsicRuleService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpReferenceTableIntrinsicRuleMeta,
        'takeUpReferenceTableIntrinsicRule',
        'referenceTableId',
        'referenceTable',
        takeUpReferenceTableIntrinsicRuleService
      )
      return takeUpReferenceTableIntrinsicRuleMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getTakeUpReferenceTableIntrinsicGradeRuleMeta(),
        this.getTakeUpReferenceTableIntrinsicRuleMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMetaWithValidation(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>
